<template>
  <div class="pa-5 interview-stages-container">
    <v-flex class="text-xs-left headline">{{
      $vuetify.lang.t("$vuetify.setting_page.interview_stages.title")
    }}</v-flex>
    <v-alert
      outlined
      v-ripple
      v-model="show_error"
      :color="color"
      transition="scale-transition"
      dismissible
    >
      {{ error_message }}
    </v-alert>

    <v-row align="center">
      <v-col cols="10">
        <v-text-field
          :label="$vuetify.lang.t('$vuetify.enter_custom_status_here')"
          v-model="new_status"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click="add" outlined color="secondary">
          {{ $vuetify.lang.t("$vuetify.add") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-list class="my-5">
      <draggable
        v-model="items"
        @start="drag = true"
        @end="drag = false"
        class="stages_list"
      >
        <div
          v-for="(element, index) in items"
          class="mt-2 mr-2 stages"
          :key="index"
        >
          <v-list-item>
            <v-list-item-avatar class="num">
              {{ index + 1 }}.
            </v-list-item-avatar>
            <v-list-item-title>{{ element }}</v-list-item-title>
            <v-list-item-action>
              <v-btn @click="remove(index)" icon ripple>
                <v-icon color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < items.length"></v-divider>
        </div>
      </draggable>
    </v-list>
    <v-flex class="mt-3 text-center">
      <v-btn
        color="secondary"
        class="mr-5"
        @click="saveStages"
        :loading="loading"
        >{{ $vuetify.lang.t("$vuetify.apply_changes") }}</v-btn
      >
      <v-btn outlined color="secondary" @click="reset">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapMutations } from "vuex";
export default {
  name: "setting-interview-stages",
  components: {
    draggable,
  },
  data() {
    return {
      copy: [],
      items: [],
      loading: false,
      new_status: "",
      show_error: false,
      error_message: "Updated Successfully",
      color: "success",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    add() {
      if (this.new_status) {
        this.items.push(this.new_status);
        this.new_status = "";
      }
    },
    remove(index) {
      this.items.splice(index, 1);
    },
    async saveStages() {
      try {
        this.loading = true;
        const resp = await this.axios.put(`/company/${this.user.company_id}`, {
          stages: this.items,
        });
        const stages = resp.data.stages;
        this.SET_USER({
          ...this.user,
          company: {
            ...this.user.company,
            stages,
          },
        });
        this.color = "success";
        this.error_message = "Updated Successfully";
        this.show_error = true;
        this.loading = false;
      } catch (error) {
        this.color = "error";
        this.error_message = "Something went wrong";
        this.show_error = true;
        this.loading = false;
        console.log(error);
      }
    },
    reset() {
      this.items = [...this.user.company.stages];
    },
  },
  created() {
    this.copy = [...this.user.company.stages];
    this.items = [...this.user.company.stages];
  },
};
</script>

<style lang="scss" scoped>
.interview-stages-container {
  .stages {
    background-color: #eaeef2;
    width: 45%;
    .num {
      color: #7f828f !important;
    }
  }
  .stages_list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
